<template>
  <div class="pages-content">
    <div class="text-center loading" v-if="!dataLoaded">
      <p>
        <i class="fa fa-spinner fa-spin"></i>
      </p>
      <p>書籍信息載入中...</p>
    </div>
    <div class="row" v-else>
      <div class="col-xs-2">
        <div class="side-timeline">
          <div :class="['time_year', {'active': currentYear === year}]" @click="setActiveYear(year)"
               v-for="(year, index) in currentYears" :key="index">{{year}}</div>
        </div>
      </div>
      <div class="col-xs-7">
        <template v-if="contents.length">
          <action-item v-for="(item, index) in contents"
                       @select="toggleSelect(item, index)"
                       :info="item" :key="index"></action-item>
        </template>
        <template v-else>
          <p class="text-center">暫無內容</p>
        </template>
      </div>
      <div class="col-xs-2 selection__actions">
        <button class="btn primary" @click="saveContent(false)">
          <i class="fa fa-floppy-o" aria-hidden="true"></i>
          保存编辑
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'src/api'
import ActionItem from 'src/components/layout/ActionItem.vue'
import { camelCase, orderBy, findIndex } from 'lodash'
export default {
  name: '',
  components: { ActionItem },
  data: () => ({
    contents: [],
    workBox: { facebook: [], patch: [], albums: [] },
    currentYear: '',
    currentYears: [],
    timeLine: {},
    dataLoaded: false,
    saving: false
  }),
  computed: {},
  created () {
    const { pages, sid } = this.$route.query
    if (sid) {
      this.fetchPages(sid, pages)
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.saving) this.saveContent(true)
    next()
  },
  methods: {
    // 请求粉丝页数据
    fetchPages (sid, pages) {
      api.fetchSocialInfo(sid, pages).then(data => {
        if (data && data.message) {
          return
        }
        Object.keys(data).map(item => {
          const lowerCaseItem = item.toLocaleLowerCase()
          data[lowerCaseItem] = data[item]
        })
        const letter = camelCase(pages).toLocaleLowerCase()
        const { timeLine, years, activeYear } = this.handleContentData(data[letter])
        this.timeLine = timeLine
        this.contents = timeLine[activeYear] || []
        this.currentYears = years
        this.currentYear = activeYear
        this.dataLoaded = true
      }).catch(e => {
        console.log(e)
        this.dataLoaded = true
      })
    },
    // 处理粉丝页数据
    handleContentData (data) {
      const timeLine = {}
      let years = []
      this.workBox[this.$route.query.pages] = []
      const checked = data.checked.map(item => {
        item.checked = true
        if (!item.selected) {
          const itemId = item.month
          this.workBox[this.$route.query.pages].push({ itemId, selected: false }) // 进入删除列表
        }
        return item
      })
      const unchecked = data.unchecked.map(item => {
        item.checked = false
        return item
      })
      const contents = checked.concat(unchecked)
      data = orderBy(checked.concat(unchecked), ['month'], ['asc'])
      data.forEach(item => {
        const year = item.month.substr(0, 4)
        const month = item.month.substr(4, 2)
        timeLine[year] = timeLine[year] || []
        timeLine[year].push(Object.assign(item, { month, time: item.month }))
      })
      years = Object.keys(timeLine).reverse()
      const activeYear = years[0]
      return { contents, years, activeYear, timeLine }
    },
    // 设置当前时间
    setActiveYear (year) {
      this.currentYear = year
      this.contents = this.timeLine[year] || []
    },
    toggleSelect (item, index) {
      if (item.checked && !item.selected) {
        this.$toast({
          message: '請恢復條目內容'
        })
        return
      }
      this.$set(this.contents[index], 'checked', !item.checked)
      const itemId = item.time
      const activeIndex = findIndex(this.workBox[this.$route.query.pages], { itemId })
      if (activeIndex > -1) {
        this.workBox[this.$route.query.pages].splice(activeIndex, 1)
      } else {
        this.workBox[this.$route.query.pages].push({ itemId, selected: item.checked })
      }
    },
    saveContent (force = false) {
      const updateContent = { facebook: {}, albums: {}, patch: {} }
      updateContent[this.$route.query.pages] = {}
      Object.keys(this.workBox).forEach(key => {
        updateContent[key].add = []
        updateContent[key].remove = []
        this.workBox[key].forEach(item => {
          if (item.selected) {
            updateContent[key].add.push(item.itemId)
          } else {
            updateContent[key].remove.push(item.itemId)
          }
        })
      })
      api.updateSocialBook(this.$route.query.sid, updateContent).then(data => {
        if (!force) {
          this.$toast({
            message: '内容更新成功',
            icon: 'fa fa-check-circle'
          })
          this.saving = true
          const query = Object.assign({}, this.$route.query)
          delete query.pages
          this.$router.push({ name: 'selection', query })
        }
      })
    }
  }
}
</script>

<style type="text/scss" lang="scss">
@import '../../../styles/variables';
.pages-content {
  margin-top: 4rem;
  .loading {
    i {
      font-size: 2rem ;
    }
    p{
      margin: 1rem auto;
    }
  }
  .sidebar__timeline {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
  }
  .time_year {
    height: 54px;
    line-height: 54px;
    width: 100%;
    max-width: 100px;
    text-align: center;
    color: #999;
    font-weight: 600;
    background-color: #f9f9f9;
    cursor: pointer;
    &.active {
      border-left: 4px solid $el-color-primary;
      background-color: #fff;
    }
  }
  .selection__actions {
    .btn {
      min-width: 120px;
    }
    .btn + .btn {
      margin-top: 1rem;
    }
  }
}
</style>
